import React from 'react';

import {Trans, useTranslation} from 'react-i18next';

import {BuilderPropsGroup, LocalizedTextType, PropsBuilder} from '../../../../types';
import {usePropsByViewPortSize} from '../../../../utils';
import {ColorInput} from '../../../ColorInput';
import {LocalizedInput} from '../../../LocalizedInput';
import {Select} from '../../../Select';
import {TextArea} from '../../../TextArea';
import {EditSectionElement, SliderInputValuePicker, SelectButtons} from '../../components';
import {PropsBuilderContainer} from '../../components/PropsBuilderContainer';

import {TextElementProps} from './index';

export const _TextPropsBuilder: PropsBuilder<TextElementProps> = ({value: propsValue, onChange, onReset}) => {
  const {lineHeight, fontSize, value, color, backgroundColor, fontWeight, textAlign} =
    usePropsByViewPortSize(propsValue);
  const {t} = useTranslation();

  const onUpdateProps = (key: string, value: number | string | LocalizedTextType) => {
    onChange(key, value);
  };

  const onResetProps = (keys: string[]) => {
    onReset?.(keys);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<Trans i18nKey="content" />} onReset={() => onResetProps(['value'])}>
        <LocalizedInput
          value={value}
          onChange={(value) => {
            onUpdateProps('value', value);
          }}
          input={({localizedValue, onLocalizedValueChange}) => (
            <TextArea
              textAreaProps={{padding: '$4', color: '$neutral-600', lineHeight: 20, fontWeight: '500', fontSize: 14}}
              padding="$0"
              value={localizedValue}
              onChangeText={onLocalizedValueChange}
            />
          )}
        />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.typography.fontSize" />}
        onReset={() => onResetProps(['fontSize'])}>
        <SliderInputValuePicker
          max={100}
          defaultValue={30}
          value={fontSize as any}
          propName="fontSize"
          onUpdate={onUpdateProps}
        />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.typography.lineHeight" />}
        onReset={() => onResetProps(['lineHeight'])}>
        <SliderInputValuePicker
          max={100}
          defaultValue={30}
          value={lineHeight as any}
          propName="lineHeight"
          onUpdate={onUpdateProps}
        />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.typography.textAlign" />}
        onReset={() => onResetProps(['textAlign'])}>
        <SelectButtons
          value={textAlign}
          options={[
            {
              action: () => onUpdateProps('textAlign', 'left'),
              icon: 'AlignLeft',
              lable: t('settings.layout.left'),
              optionValue: 'left',
            },
            {
              action: () => onUpdateProps('textAlign', 'center'),
              icon: 'AlignCenter',
              lable: t('settings.layout.center'),
              optionValue: 'center',
            },
            {
              action: () => onUpdateProps('textAlign', 'right'),
              icon: 'AlignRight',
              lable: t('settings.layout.right'),
              optionValue: 'right',
            },
          ]}
        />
      </EditSectionElement>
      <EditSectionElement title={<Trans i18nKey="settings.style.color" />} onReset={() => onResetProps(['color'])}>
        <ColorInput
          value={color || 'black'}
          onChange={(value) => onUpdateProps('color', value)}
          inputProps={{width: '80%'}}
        />
      </EditSectionElement>

      <EditSectionElement
        title={<Trans i18nKey="settings.style.backgroundColor" />}
        onReset={() => onResetProps(['backgroundColor'])}>
        <ColorInput
          value={backgroundColor || 'transparent'}
          onChange={(value) => onUpdateProps('backgroundColor', value)}
          inputProps={{width: '80%'}}
        />
      </EditSectionElement>

      <EditSectionElement
        title={<Trans i18nKey="settings.typography.fontWeight" />}
        onReset={() => onResetProps(['fontWeight'])}>
        <Select
          value={fontWeight}
          options={[
            {label: '700', value: '700'},
            {label: '600', value: '600'},
            {label: '500', value: '500'},
            {label: '400', value: '400'},
          ]}
          onChange={(value) => onUpdateProps('fontWeight', value)}
          placeholder="400"
          width="80%"
        />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const TextPropsBuilder: BuilderPropsGroup<TextElementProps> = {
  title: 'content',
  sections: [
    {
      title: 'settings.basic.text',
      Content: _TextPropsBuilder,
    },
  ],
};
